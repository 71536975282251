// App.js
import "./index.scss";
import "react-toastify/dist/ReactToastify.min.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ErrorBoundary } from "react-error-boundary";
import { routes } from "./routes";
import { AppContextProvider, useAppContext } from "contexts/AppContext";
import AppRouter from "./AppRouter";
import Navbar from "components-layout/Navbar";
import Footer from "components-layout/Footer/Footer";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

function AppWrapper() {
  const {
    state: { isAuthenticated },
    setAuth,
  } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [profileAvailable, setProfileAvailable] = useState(false);

  useEffect(() => {
    const storedResponse = JSON.parse(localStorage.getItem("response"));
    if (storedResponse) {
      setProfileAvailable(storedResponse.profileAvailable);
    }

    const params = new URLSearchParams(location.search);
    const redir = params.get("redir");
    const l = params.get("l");
    const c = params.get("c");
    const b = params.get("b");
    // console.log("URL Parameters:", { redir, l, c, b });

    if (redir && b && c && l) {
      const redirectPath = `/gate/lessons/${l}?courseId=${c}&branchId=${b}`;
      // console.log("Constructed Redirect Path:", redirectPath);

      if (isAuthenticated) {
        navigate(redirectPath);
      } else {
        localStorage.setItem("redirectPath", redirectPath);
      }
    }
  }, [isAuthenticated, location.search, navigate]);

  const handleLogout = () => {
    setAuth(false);
    localStorage.removeItem("token");
    localStorage.removeItem("response");
  };

  const noNavNFooter =
    location.pathname === routes.Signup ||
    location.pathname === routes.Login ||
    location.pathname === routes.Otp;

  return (
    <>
      <ScrollToTop />

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      {!noNavNFooter && profileAvailable && (
        <Navbar isAuthenticated={isAuthenticated} onLogout={handleLogout} />
      )}
      <div className={"mainWrapper"}>
        <AppRouter />
      </div>
      {!noNavNFooter && profileAvailable && <Footer />}
    </>
  );
}

function App() {
  return (
    <ErrorBoundary fallback={<div>Fatal Error</div>}>
      <AppContextProvider>
        <BrowserRouter>
          <AppWrapper />
        </BrowserRouter>
      </AppContextProvider>
    </ErrorBoundary>
  );
}

export default App;
