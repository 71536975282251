import React from "react";
import { Link } from "react-router-dom";
import style from "./Footer.module.css";
import Logo from "../../assets/logo/logo12.png";
import Fb from "../../assets/icons/fb.png";
import Ig from "../../assets/icons/ig.png";
import X from "../../assets/icons/x.png";
import Yt from "../../assets/icons/yt.png";
import In from "../../assets/icons/in.png";
import location from "../../assets/icons/location.png";
import mail from "../../assets/icons/mail.png";
import phone from "../../assets/icons/phone.png";
import { fontSize } from "@mui/system";

function Footer() {
  return (
    <footer>
      <div className={style.top}>
        <div className={`${style.content} ${style.left}`}>
          <div className={style.sect2}>
            <img src={Logo} alt="" width="210px" height="auto" />
            <div className={style.footerDesc}>
              The story of Msigma Gokulam is all about the passion for teaching
              and the will to make a change
            </div>
          </div>
        </div>
        <div className={style.pages}>
          <ul>
            <div className={style.sectionHeading}>QUICK LINKS</div>
            <div className={style.sectionItems}>
              <div>
                <li>
                  <Link style={{ fontSize: "14px", fontWeight: "500" }} to="/">
                    Home
                  </Link>
                </li>

                {/* <li>
                  <Link
                    style={{ fontSize: "14px", fontWeight: "500" }}
                    to="/About"
                  >
                    Courses
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ fontSize: "14px", fontWeight: "500" }}
                    to="/About"
                  >
                    About Us
                  </Link> 
                </li>*/}
              </div>
            </div>
          </ul>

          <ul>
            <div className={style.sectionHeading}>All Courses</div>

            <li>
              <Link
                style={{ fontSize: "14px", fontWeight: "500" }}
                to="/branches/5"
              >
                GATE & PSU
              </Link>
            </li>
            <li>
              <Link
                style={{ fontSize: "14px", fontWeight: "500" }}
                to="/branches/2"
              >
                B.Tech
              </Link>
            </li>
            {/* <li>
              <Link style={{ fontSize: "14px", fontWeight: "500" }} to="/">
                B.Sc Electronics
              </Link>
            </li>
            <li>
              <Link style={{ fontSize: "14px", fontWeight: "500" }} to="/">
                PSC Engineering Jobs
              </Link>
            </li> */}
            <li>
              <Link
                style={{ fontSize: "14px", fontWeight: "500" }}
                to="/branches/4"
              >
                ISRO
              </Link>
            </li>
            <li>
              <Link
                style={{ fontSize: "14px", fontWeight: "500" }}
                to="/branches/6"
              >
                IES & ESE
              </Link>
            </li>
          </ul>

          <ul>
            <div className={style.sectionHeading}>Contact Us</div>
            <li>
              <img src={phone} alt="phone" style={{ marginTop: "4px" }} />
              <a
                style={{ fontSize: "14px", fontWeight: "500" }}
                href="tel:+91 8921407459"
              >
                +91 89214 07459
              </a>
            </li>
            <li style={{ marginTop: "10px", marginLeft: "30px" }}>
              <a
                style={{ fontSize: "14px", fontWeight: "500" }}
                href="tel:+91 7356489399"
              >
                +91 73564 89399
              </a>
            </li>
            <li>
              <img src={mail} alt="mail" style={{ marginTop: "4px" }} />
              <a
                style={{ fontSize: "14px", fontWeight: "500" }}
                href="mailto:support@msigmagokulam.com"
              >
                support@msigmagokulam.com
              </a>
            </li>
            <li>
              <img src={location} alt="location" style={{ marginTop: "4px" }} />
              <Link
                style={{ lineHeight: "2", fontSize: "14px", fontWeight: "500" }}
                to="https://www.google.com/maps/place/Msigma+Gokulam/@8.5364936,76.9224721,18.82z/data=!4m7!3m6!1s0x3b05b9523836d0cd:0x8c4368c4da964a6e!4b1!8m2!3d8.5362957!4d76.9229032!16s%2Fg%2F11smm1trmn?entry=ttu"
              >
                Msigma Gokulam Pvt Ltd. PNRA – 40 <br />
                Opp St. Alphonsa Church, Priyadarshini
                <br />
                Nagar, Pongumoodu, P.O, Sreekariyam,
                <br />
                Thiruvananthapuram, Kerala 695017
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className={style.info}>
        <div className={style.socialMedia}>
          <Link to="https://www.facebook.com/">
            <img src={X} alt="X" />
          </Link>
          <Link to="https://www.x.com/">
            <img src={Ig} alt="Ig" />
          </Link>
          <Link to="https://www.facebook.com/">
            <img src={Fb} alt="fb" />
          </Link>
          <Link to="/">
            <img src={In} alt="in" />
          </Link>

          <Link to="">
            <img src={Yt} alt="yt" />
          </Link>
        </div>
        <div
          className={style.copyright}
          style={{ fontSize: "14px", fontWeight: "300" }}
        >
          © 2024 Msigma Gokulam Pvt Ltd.. All rights reserved
        </div>
        <div className={style.legal} style={{ fontSize: "14px" }}>
          <Link
            to="https://www.msigmagokulam.com/terms"
            target="_blank"
            style={{ marginRight: "15px" }}
          >
            Terms & Conditions
          </Link>
          <Link
            to="https://www.msigmagokulam.com/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
