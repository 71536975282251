export const routes = {
  Home: "/",
  Login: "/login",
  Signup: "/signup",
  Otp: "/otp",
  Branches: "/branches/:id",
  Lessons: "/lessons/:id",
  GateLessons: "gate/lessons/:id",
  Announcement: "/announcements",
  Subjects: "subjects/:id",
  RazorPay: "/razorpay/:id",
  MyCourses: "/my_courses",
  TestResults: "/test-results",
  TestResultsSubWise: "/results/:subjectName",
};
