import React, { Suspense, lazy, useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { routes } from "./routes";
import Preloader from "components-shared/Preloader/Preloader";
import { useAppContext } from "contexts/AppContext";

const Home = lazy(() => import("component-pages/Home"));
const Lessons = lazy(() => import("component-pages/Lessons copy"));
const GateLessons = lazy(() => import("component-pages/GateLessons copy"));
const Announcement = lazy(() => import("component-pages/Announcements"));

const Branches = lazy(() => import("component-pages/Branches"));
const Subjects = lazy(() => import("component-pages/Subjects"));
const MyCourses = lazy(() => import("component-pages/MyCourses"));
const TestResults = lazy(() => import("component-pages/TestResults"));
const TestResultsSubWise = lazy(() =>
  import("component-pages/TestResultsSubWise")
);

const Otp = lazy(() => import("component-pages/OtpPage/Otp"));
const SignupPage = lazy(() => import("component-pages/Signup copy"));
const Login = lazy(() => import("component-pages/Login"));

const RazorpayPayment = lazy(() =>
  import("component-pages/Subjects/components/razorpay")
);

export default function AppRouter() {
  const {
    state: { isAuthenticated },
    setAuth,
  } = useAppContext();
  const navigate = useNavigate();
  const [profileAvailable, setProfileAvailable] = useState(false);
  const [storedResponse, setStoredResponse] = useState(null);

  useEffect(() => {
    const storedResponse = JSON.parse(localStorage.getItem("response"));
    if (storedResponse) {
      setStoredResponse(storedResponse);
      setProfileAvailable(storedResponse.profileAvailable);
    }
  }, []);

  const handleLogin = (response) => {
    setAuth(true);
    localStorage.setItem("response", JSON.stringify(response));
    localStorage.setItem("token", response.token);

    if (response.profileAvailable) {
      setProfileAvailable(true);
      const redirectPath = localStorage.getItem("redirectPath") || "/";
      localStorage.removeItem("redirectPath");
      navigate(redirectPath);
    } else {
      setProfileAvailable(false);
      navigate("/");
    }
  };

  const handleSubmissionSuccess = (success) => {
    if (success) {
      setProfileAvailable(true);
      if (storedResponse) {
        setStoredResponse({ ...storedResponse, profileAvailable: true });
        localStorage.setItem(
          "response",
          JSON.stringify({ ...storedResponse, profileAvailable: true })
        );
        const redirectPath = localStorage.getItem("redirectPath") || "/";
        localStorage.removeItem("redirectPath");
        navigate(redirectPath);
      }
      window.location.reload();
    }
  };

  return (
    <Suspense fallback={<Preloader />}>
      <Routes>
        <Route
          path={routes.Login}
          element={isAuthenticated ? <Navigate to="/" /> : <Login />}
        />
        <Route
          path="/otp"
          element={
            isAuthenticated ? (
              <Navigate to="/" />
            ) : (
              <Otp onLogin={handleLogin} />
            )
          }
        />
        {isAuthenticated ? (
          <>
            {profileAvailable ? (
              <>
                <Route path="/" element={<Home />} />
                <Route path={routes.Lessons} element={<Lessons />} />
                <Route path={routes.GateLessons} element={<GateLessons />} />
                <Route path={routes.Announcement} element={<Announcement />} />
                <Route path={routes.RazorPay} element={<RazorpayPayment />} />
                <Route path={routes.Branches} element={<Branches />} />
                <Route path={routes.Subjects} element={<Subjects />} />
                <Route path={routes.MyCourses} element={<MyCourses />} />
                <Route path={routes.TestResults} element={<TestResults />} />
                <Route
                  path={routes.TestResultsSubWise}
                  element={<TestResultsSubWise />}
                />
                <Route path="/*" element={<Navigate to="/" />} />
              </>
            ) : (
              <Route
                path="/"
                element={
                  <SignupPage
                    handleSubmissionSuccess={handleSubmissionSuccess}
                  />
                }
              />
            )}
          </>
        ) : (
          <Route path="/*" element={<Navigate to="/login" />} />
        )}
      </Routes>
    </Suspense>
  );
}
